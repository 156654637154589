<template>
    <div class="jiangYi">
        <!-- 讲义弹窗 -->
        <van-popup v-model="jiangYishow" position="bottom" :overlay="false" get-container="#app">
            <div class="jyPopup">
                <div class="jyPopup_close">
                    <span @click="downLoad">下载</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span @click="cancel">取消</span>
                </div>
                <p class="jyPopup_title">{{filePath}}</p>
                <div class="wordBox">
                    <div v-if="filePath && fileType =='doc'" ref="word"></div>
                    <div v-if="filePath && fileType =='pdf'">
                        <pdf  v-for="i in numPages" :key="i" :page="i" :src="filePath" style="display: inline-block; width: 100%;height:100%"/>
                    </div>
                    <iframe id="ifr" v-if="fileType == 'ppt'" :src="'https://view.officeapps.live.com/op/view.aspx?src=' + filePath" class="iframe" frameborder="0" width="100%" height="100%"></iframe>
                </div>
            </div>
        </van-popup>

        <!-- 讲义卡片 -->
        <div class="card" v-for="(item,i) in jiangYiList" :key="i">
            <div class="left">
                <div class="title">
                    <img :src="iconHandOut" alt="#" class="imgSize">
                    <span class="title_sp">{{item.coursewareName}}</span>
                </div>
                <div class="time">
                    <span>上架时间：{{item.intime}}</span>
                </div>
            </div>

            <div>
                <div class="seeBtn" @click="onSee(item)">查看</div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { courseware } from '@/api/selectClass.js'
import pdf from 'vue-pdf' //引入下载的vue-pdf

import axios from '@/axios'
const docx = require("docx-preview");
window.JSZip = require("jszip");

export default {
    props:['courseId','getContainer'],
    components:{pdf},
    data() {
        return {
            iconHandOut: require('@/assets/all_Icon/drawable-xxhdpi/icon_handout.png'),
            id:'152',

            //讲义
            jiangYiList:[], //数据
            jiangYishow:false, //弹窗
            filePath:'', //讲义路径如：https://file.saen.com/web/i2X3wbFWBz1645776407647.docx
            fileType:'doc',

            // 展示pdf文件相关
            iframeUrl:'', //转化后的路径
            isPdfShow:false,
            numPages:0,
        }
    },
    created() {
        this.getCourseware()
    },
    mounted(){
        
    },
    methods: {
        // 获取讲义
        getCourseware(){
            let data = new FormData()
            data.append('courseId',this.courseId)
            courseware(data).then(res=>{
                // console.log('jiangYi-获取讲义---',res)
                if(res.data.code == 0){
                    this.jiangYiList = res.data.data
                }
            })
        },
        // 查看讲义
        onSee(item){
            console.log('ppt-----------',item.filePath)

            // word文件
            if( item.filePath.indexOf('.doc') >0 || item.filePath.indexOf('.docx') >0 ){
                this.filePath = item.filePath
                this.fileType = 'doc'
                this.jiangYishow = true
                this.readOnline()
                console.log('doc------------',item.filePath)
            }

            // pdf文件
            if( item.filePath.indexOf('.pdf') >0 ){
                this.filePath = item.filePath
                this.fileType = 'pdf'
                this.jiangYishow = true
                console.log('pdf------------')
            }

            // ppt文件
            if( item.filePath.indexOf('.ppt') >0 || item.filePath.indexOf('.pptx') >0 ){
                this.filePath = item.filePath
                this.fileType = 'ppt'
                this.jiangYishow = true
                console.log('ppt-----------',item.filePath)
            }

        },
        // 预览讲义
        readOnline(){
            axios({
                url: this.filePath,
                method: 'get',
                responseType: 'blob',
            }).then(res=>{
                console.log('在线预览讲义docx-------',res)
                const blob = new Blob([res.data]);
                if(this.fileType == 'doc') docx.renderAsync(blob, this.$refs.word);

                if(this.fileType == 'pdf') {
                    const blob2 = new Blob([res.data],{ type: 'application/pdf' });
                    this.iframeUrl = URL.createObjectURL(blob2)
                }
                
            })
        },
        // 下载讲义
        downLoad(){
            this.$toast('下载讲义')
            if(this.fileType == 'doc') window.location.href = this.filePath
            if(this.fileType == 'pdf' || this.fileType == 'ppt') {
                const link = document.createElement('a');
                link.href = this.filePath;

                link.setAttribute('download', this.fileType == 'pdf'? '讲义.pdf' : '讲义.ppt');
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放内存
            }
        },

        // 取消-关闭讲义
        cancel(){
            this.jiangYishow=false
            this.filePath = ''
        }

    },
    watch:{
        filePath: {
            handler: function(val) {
                if (!val) {
                    return
                }
                this.srcTask = pdf.createLoadingTask(val)
                // this.srcTask.onProgress = val => {
                //     let ratio = (val.loaded / val.total) * 100
                //     if (ratio >= 100) {
                //         ratio = 100
                //     }
                //     this.loadedRatio = ratio.toFixed(2)
                // }
                this.srcTask.promise.then(pdf => {
                    console.log({ pdf })
                    this.numPages = pdf.numPages
                    
                    // this.fileInfo.pageNum = pdf.numPages
                    // this.$emit('loaded', this.fileInfo)
                })
            },
            immediate: true
        }
    }
}
</script>

<style lang="less" scope>
.jiangYi {
    border-radius: 5px;
    font-family: PingFangSC-Regular, PingFang SC;

    .imgSize {
        width: 20px;
        height: 20px;
    }

    .card {
        height: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        margin-bottom: 16px;

        .left{
            margin-left: 10px;

            .title {
                font-size: 16px;
                color: #141414;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                > img{
                    margin-right: 10px;
                }
            }
            .time{
                font-size: 13px;
                color: #666666;
            }
        }
        

        .seeBtn {
            width: 58px;
            height: 28px;
            line-height: 28px;
            color: #fff;
            background: #5f7dff;
            border-radius: 5px;
            text-align: center;
            margin-right: 10px;
        }
    }
}
</style>

<style lang="less">
// 讲义弹窗
.jyPopup{
    z-index: 9999999;

    .iframe{
        overflow-y: auto; 
        ::-webkit-scrollbar {
        	width: 10px;
        	height: 10px;
        	background: #0e0c0c;
        	padding-right: 2px;
        }
        ::-webkit-scrollbar-thumb {
        	background: #b8e909;
        	border-radius: 10px;
        }
    }

    .jyPopup_close{
        text-align: right;
        padding-right: 20px;
        font-size: 16px;
        color: blue;
        height: 35px;
        line-height: 35px;
        border-top: 0.5px solid #ccc;
    }

    .jyPopup_title{
        background-color: #002180;
        height: 40px;
        padding-left: 20px;
        line-height: 40px;
        color: #fff;
    }

    .wordBox{
        height:50vh;
        background-color: #f5f5f5;
        overflow:scroll;

        .docx-wrapper{
            padding: 0!important;
            width: 100%!important;
        }
        .docx{
            width: 100%!important;
            display: div!important;
            padding: 10px!important;

            img{
                max-width:350px!important;
                height: auto!important;
            }
        }
    }

    
}
</style>

<style>
</style>

