<template>
    <div class="videoPlayer">
        <div id="player"></div>
        
    </div>
</template>

<script>
import tabBar from '@/components/tabBar'
import {playerSafe} from '@/api/selectClass.js'
import md5 from 'js-md5'

export default {
    props:{
        vid:{
            type:String,
            default:''
        }
    },
    data() {
        return {
            player: null,
            playsafe:'',
            sign:'',
            ts:'',

            option:{
                vid: ''
            }

        };
    },
    watch:{
        vid(newVal,oldVal){
            this.option.vid = newVal
            this.reportSpeed() //切换视频时 触发上报
            //this.player.changeVid(this.option)
            this.playerSafeFun();
        }
    },
    created() {
        this.option.vid = this.vid
    },
    mounted() {
        this.playerSafeFun()
        
    },
    methods: {
        // 获取playsafe、生成sign  播放视频步骤1
        async playerSafeFun(){
            // 获取playsafe
            let data = new FormData();
            console.log("this.vid",this.vid);
            data.append('videoId',this.vid)
            let res = await playerSafe(data)
            // console.log('----------',res)

            if(res.data.code != 200) return this.$toast('获取playsafe失败')
            this.playsafe = res.data.data.token
            
            // 生成sign和时间戳
            let date = new Date().getTime()
            this.sign = md5('7Fwj0lMEPU'+ this.vid+ date)
            this.ts = date
            
            this.loadPlayer()
        },
        
        // 加载播放器  播放视频步骤2
        loadPlayer(){
            // console.log('加载播放器-步骤3---',this.sign,this.ts,this.playsafe)
            if (this.player) this.player.destroy()
            this.player = polyvPlayer({
                wrap: "#player",
                width: '100%',
                height: '',
                forceH5: true,
                vid: this.option.vid, //云点播平台的视频唯一id
                playsafe: this.playsafe, //pc端播放加密视频的凭证
                sign:this.sign, //用于移动和H5：播放加密视频的签名
                ts:this.ts, //用于移动和H5：播放加密视频的时间戳
            })
            this.player.on('s2j_onPlayOver',(e) => {
                this.reportSpeed() //播放结束时 触发上报
            })
        },

        // 上报视频进度
        reportSpeed(){
            this.$emit('planReportedData',this.player.j2s_getCurrentTime())
        }
        
    },
    beforeDestroy() {
        //关闭页面时 触发上报
        this.$emit('planReportedData',this.player.j2s_getCurrentTime())
    },
    destroyed() {
        if (this.player) {
            this.player.destroy()
        }
    }
};
</script>

<style lang="less" scoped>

</style>


